import { http } from "../../../api/http-common";

const getAllFactorActionPlan = async (params) => {
    try {
        const { data } = await http.get("satisfaction-criterias", { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const saveFactorActionPlan = async (payload) => {
    try {
        const { data } = await http.post("satisfaction-criterias", payload);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

export {
    getAllFactorActionPlan,
    saveFactorActionPlan,
};
