import { http } from "../../../api/http-common";

const getAllQuestions = async (params) => {
  try {
    const { data } = await http.get("questions", { params });
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getQuestionById = async (id) => {
  try {
    const { data } = await http.get(`questions/${id}`);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveQuestion = async (payload) => {
  try {
    const { data } = await http.post("questions", payload);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { getAllQuestions, getQuestionById, saveQuestion };
