import * as actionPlanService from "./service/action-plan.service";

const state = {
  actionPlan: null,
  actionPlans: [],
  actionPlansPagination: {
    page: 1,
    limit: 10,
    totalItems: 0,
    totalPages: 0,
  },
  allActionPlans: [],
  loadingActionPlan: false,
  actionPlansGroupedByService: [],
  generalProgress: null,
  actionPlanReport: null,
  reportDashboardData: null,
};

const mutations = {
  SET_LOADING_ACTION_PLAN(state, data) {
    state.loadingActionPlan = data;
  },

  SET_ACTION_PLAN(state, actionPlan) {
    state.actionPlan = actionPlan;
  },

  SET_ALL_ACTION_PLANS(state, data) {
    state.allActionPlans = data;
  },

  SET_ACTION_PLANS(state, payload) {
    state.actionPlans = payload;
  },
  
  SET_ACTION_PLANS_PAGINATION(state, payload) {
    state.actionPlansPagination = {
      ...state.actionPlansPagination,
      ...payload,
    };
  },
  SET_ACTION_PLAN_GROUPED_BY_SERVICE(state, payload) {
    state.actionPlansGroupedByService = payload;
  },
  SET_ACTION_PLAN_GENERAL_PROGRESS(state, payload) {
    state.generalProgress = payload;
  },
  SET_ACTION_PLAN_REPORT(state, payload) {
    state.actionPlanReport = payload;
  },
  SET_REPORT_DASHBOARD_DATA(state, payload) {
    state.reportDashboardData = payload;
  }

  // SET_PAGE(state, page) {
  // 	state.assets.page = page;
  // },

  // SET_LIMIT(state, limit) {
  // 	state.assets.limit = limit;
  // },
};

const actions = {
  async createActionPlan(__, data) {
    try {
      const response = await actionPlanService.saveActionPlan(data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async getActionPlanById({ commit }, id) {
    try {
      const response = await actionPlanService.getActionPlanById(id);
      commit("SET_ACTION_PLAN", response);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listAllActionPlans({ commit }, data) {
    try {
      const response = await actionPlanService.getAllActionPlans(data);
      commit("SET_ALL_ACTION_PLANS", response.data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listActionPlanPagination({ commit }, data) {
    try {
      commit("SET_ACTION_PLANS", []);
      const response = await actionPlanService.getAllActionPlans(data);
      const pagination = {
        page: response?.pagination.page,
        limit: response?.pagination.limit,
        totalItems: response?.pagination.totalItems,
        totalPages: response?.pagination.totalPages,
      };
      commit("SET_ACTION_PLANS", response?.data || []);
      commit("SET_ACTION_PLAN_GENERAL_PROGRESS", response?.generalProgress);
      commit("SET_ACTION_PLANS_PAGINATION", pagination || {});
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async proposalUpdateActionPlan(__, databody) {
    try {
      const { id, payload } = databody;
      const response = await actionPlanService.saveProposalUpdateActionPlan(id, payload);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async replyProposalUpdateActionPlan(__, databody) {
    try {
      const { id, payload } = databody;
      const response = await actionPlanService.saveReplyProposalUpdateActionPlan(id, payload);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async editActionPlan(__, databody) {
    try {
      const { id, payload } = databody;
      const response = await actionPlanService.updateActionPlan(id, payload);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listActionPlansGroupedByService({ commit },data) {
    try {
      const response = await actionPlanService.getAllActionPlansGroupedByService(data);
      commit("SET_ACTION_PLAN_GROUPED_BY_SERVICE", response);
      return { ok: true, response }
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" }
    }
  },

  async downloadExportActionPlan(__, data) {
		try {
			await actionPlanService.getExportActionPlan(data);
			return { ok: true }
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

  async deletedActionPlan(__, databody){
    try{
      const { id, payload } = databody;
      const response = await actionPlanService.deleteActionPlan(id,payload);
      return { ok: true, response };
    }catch(error){
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async getActionPlanReport({ commit }, data) {
    try {
      const response = await actionPlanService.getActionPlanReport(data);
      commit("SET_ACTION_PLAN_REPORT", response);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async getReportDashboardData({ commit }, data) {
    try {
      const response = await actionPlanService.getReportDashboardData(data);
      commit("SET_REPORT_DASHBOARD_DATA", response);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  cleanActionPlanGroupedService({commit}){
    commit('SET_ACTION_PLAN_GROUPED_BY_SERVICE',[]);
  },

  cleanActionPlanId({ commit }) {
    commit('SET_ACTION_PLAN', null);
  },

  cleanActionPlan({ commit }) {
    commit("SET_ACTION_PLANS", []);
    commit("SET_ACTION_PLAN_GENERAL_PROGRESS",null);
    commit("SET_ACTION_PLANS_PAGINATION", {
      page: 0,
      limit: 0,
      totalItems: 0,
      totalPages: 0,
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
