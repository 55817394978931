import Vue from "vue";
import Vuex from "vuex";
// MODULES
import security from "./modules/security";
import auth from "./modules/auth";
import user from "./modules/user";
import personal from "./modules/personal";
import course from "./modules/course";
import action_plan from "./modules/action-plan";
import question from "./modules/questions";
import evidence_action_plan from "./modules/evidence-action-plan"
import factor_action_plan from "./modules/factors";

Vue.use(Vuex);

export default new Vuex.Store({
  // TODO: strict: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    security,
    auth,
    user,
    personal,
    course,
    action_plan,
    question,
    evidence_action_plan,
    factor_action_plan
  },
});
