import { http } from "../../../api/http-common";

const getAllActionPlans = async (params) => {
  try {
    const { data } = await http.get("action-plans", { params });
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getAllActionPlansGroupedByService = async (params) => {
  try {
    const { data } = await http.get("action-plans/grouped-service", { params });
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getActionPlanById = async (id) => {
  try {
    const { data } = await http.get(`action-plans/${id}`);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveActionPlan = async (payload) => {
  try {
    const { data } = await http.post("action-plans", payload);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveProposalUpdateActionPlan = async (id, payload) => {
  try {
    const { data } = await http.put("action-plans/propose-update/" + id, payload);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveReplyProposalUpdateActionPlan = async (id, payload) => {
  try {
    const { data } = await http.put("action-plans/reply-propose-update/" + id, payload);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateActionPlan = async (id, payload) => {
  try {
    const { data } = await http.put("action-plans/" + id, payload);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getExportActionPlan = async (params) => {
  try {
    // const { data } = await http.get('assets', { params });
    // return data || null;

    const response = await http.get('action-plans/export', { params, responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Reporte_Plan_de_accion.xlsx');
    document.body.appendChild(link);
    link.click();

  } catch (error) {
    return Promise.reject(error);
  }
}

const deleteActionPlan = async (id, payload) => {
  try {
    const { data } = await http.delete("action-plans/" + id, payload);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getActionPlanReport = async (params) => {
  try {
    const { data } = await http.get("action-plans/progress", { params });
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
}

const getReportDashboardData = async (params) => {
  try {
    const { data } = await http.get("action-plans/report-dashboard", { params });
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
}

export {
  getAllActionPlans,
  getActionPlanById,
  saveActionPlan,
  updateActionPlan,
  getAllActionPlansGroupedByService,
  deleteActionPlan,
  getExportActionPlan,
  saveProposalUpdateActionPlan,
  saveReplyProposalUpdateActionPlan,
  getActionPlanReport,
  getReportDashboardData,
};
