<template>
	<div
		class="x-navbar-wrapper"
		v-if="!['InspectionsRegisterPdf', 'IncidentPdf'].includes($route.name)"
	>
		<div class="x-navbar">
			<div class="x-navbar__left">
				<router-link to="/dashboard" class="x-link">
					<img
						class="x-navbar__logo"
						:src="
							require(['xs', 'sm'].includes($vuetify.breakpoint.name)
								? '@/assets/images/logo-cp.png'
								: '@/assets/images/logo_confipetrol.png')
						"
					/>
				</router-link>
			</div>
			<div
				class="x-links d-none d-md-flex d-print-flex"
				v-if="$route.name !== 'Dashboard'"
			>

			</div>
			<div class="x-navbar__right">
				<country-select />
				<v-menu bottom min-width="200px" rounded offset-y v-if="user">
					<template v-slot:activator="{ on }">
						<v-btn class="hidden-sm-and-down mr-8" icon x-large v-on="on">
							<v-avatar color="warning" size="48">
								<span class="white--text text-h5">{{
									getInitialName()
								}}</span>
							</v-avatar>
						</v-btn>
					</template>
					<v-card>
						<v-list-item-content class="justify-center">
							<div class="mx-auto text-center">
								<v-avatar color="warning">
									<span class="white--text text-h5">{{
										getInitialName()
									}}</span>
								</v-avatar>
								<h3 class="mt-2">{{ user.name }}</h3>
								<p class="text-caption mt-1">{{ user.email_corp }}</p>
								<v-divider class="my-3"></v-divider>
								<div class="ma-1">
									<v-btn
										:to="{ name: 'UserPermissions' }"
										active-class="no-active"
										depressed
										rounded
										text
										link
									>
										Ver Permisos
									</v-btn>
								</div>
								<v-divider class="my-3"></v-divider>
								<div class="ma-1">
									<v-btn
										:to="{ name: 'UserChangePassword' }"
										active-class="no-active"
										depressed
										rounded
										text
										link
									>
										Cambiar Contraseña
									</v-btn>
								</div>
								<v-divider class="my-3"></v-divider>
								<v-btn @click="onLogout()" depressed rounded text>
									Cerrar sesión
								</v-btn>
							</div>
						</v-list-item-content>
					</v-card>
				</v-menu>
				<v-btn
					class="hidden-md-and-up"
					icon
					color="primary"
					large
					@click="activeDrawer"
				>
					<v-icon>mdi-menu</v-icon>
				</v-btn>
			</div>
		</div>
		<!-- <div class="x-navbar-sub" v-if="isActiveSubNav">
			<div class="x-navbar-sub-item">
				<router-link to="/dashboard/inspections" class="x-link-p"></router-link>
				<div class="sub-bar">
					<router-link to="/dashboard/inspections" class="x-link">
						Inspecciones
					</router-link>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
import { mapState } from 'vuex';
import CountrySelect from '@/components/global/CountrySelect.vue';
import { $EventBus } from '@/main';
export default {
	name: 'Nav', // [SOLO] Nav a NavComponent
	props: {
		moduleList: {
			type: Array,
			required: false,
		},
	},
	data: () => ({
		isActiveSubNav: true,
	}),
	computed: {
		...mapState('auth', ['modules']),
		user() {
			return this.$store.state.auth.user || null;
		},
	},
	mounted: function () {
		this.isActiveSubNav = false;
		$EventBus.$on('showSubNav', (data) => {
			this.isActiveSubNav = data;
		});
	},
	methods: {
		getInitialName() {
			if (this.user?.name && this.user?.last_name_father) {
				const name = this.user?.name;
				const lastName = this.user?.last_name_father;
				return `${name[0]}${lastName[0]}`;
			}
		},
		activeDrawer() {
			this.$emit('switchDrawer');
		},
		onLogout() {
			this.$store.dispatch('auth/logout');
			this.$router.push({ name: 'Login', query: { error: 'Sesión terminada.' } });
		},
		checkModule(moduleName) {
			const modules = this.modules || [];
			let found = false;
			if (Array.isArray(modules)) {
				found = modules.some((module) => module.name == moduleName);
			}
			return found;
		},
	},
	components: {
		CountrySelect,
	},
};
</script>
<style lang="scss" scoped>
@import 'index.scss';
</style>
