import * as factorService from "./service/factor.service";

const state = {
  factor: null,
  factors: [],
  factorsPagination: {
    page: 1,
    limit: 10,
    totalItems: 0,
    totalPages: 0,
  },
  allFactors: [],
  loadingFactor: false,
};

const mutations = {
  SET_LOADING_FACTOR(state, data) {
    state.loadingFactor = data;
  },

  SET_FACTOR(state, factor) {
    state.factor = factor;
  },

  SET_ALL_FACTORS(state, data) {
    state.allFactors = data;
  },

  SET_FACTORS(state, payload) {
    state.factors = payload;
  },

  SET_FACTORS_PAGINATION(state, payload) {
    state.factorsPagination = { ...state.factorsPagination, ...payload };
  },
};

const actions = {
  async creatFactor(__, data) {
    try {
      const response = await factorService.saveFactorActionPlan(data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listAllFactors({ commit }, data) {
    try {
      commit("SET_ALL_FACTORS", []);
      const response = await factorService.getAllFactorActionPlan(data);
      commit("SET_ALL_FACTORS", response.data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listFactorPagination({ commit }, data) {
    try {
      commit("SET_FACTORS", []);
      const response = await factorService.getAllFactorActionPlan(data);
      const pagination = {
        page: response?.data.page,
        limit: response?.data.limit,
        totalItems: response?.data.totalItems,
        totalPages: response?.data.totalPages,
      };
      commit("SET_FACTORS", response?.data || []);
      commit("SET_FACTORS_PAGINATION", pagination || {});
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  cleanActionPlan({ commit }) {
    commit("SET_FACTORS", []);
    commit("SET_FACTORS_PAGINATION", {
      page: 0,
      limit: 0,
      totalItems: 0,
      totalPages: 0,
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
