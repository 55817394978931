import * as questionService from "./service/question.service";

const state = {
  question: null,
  questions: [],
  questionsPagination: {
    page: 1,
    limit: 10,
    totalItems: 0,
    totalPages: 0,
  },
  allQuestions: [],
  loadingQuestion: false,
};

const mutations = {
  SET_LOADING_QUESTION(state, data) {
    state.loadingQuestion = data;
  },

  SET_QUESTION(state, question) {
    state.question = question;
  },

  SET_ALL_QUESTIONS(state, data) {
    state.allQuestions = data;
  },

  SET_QUESTIONS(state, payload) {
    state.questions = payload;
  },

  SET_QUESTIONS_PAGINATION(state, payload) {
    state.questionsPagination = { ...state.questionsPagination, ...payload };
  },

  // SET_PAGE(state, page) {
  // 	state.assets.page = page;
  // },

  // SET_LIMIT(state, limit) {
  // 	state.assets.limit = limit;
  // },
};

const actions = {
  async createQuestion(__, data) {
    try {
      const response = await questionService.saveQuestion(data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async getQuestionById({ commit }, id) {
    try {
      const response = await questionService.getQuestionById(id);
      commit("SET_QUESTION", response);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listAllQuestions({ commit }, data) {
    try {
      commit("SET_ALL_QUESTIONS", []);
      const response = await questionService.getAllQuestions(data);
      commit("SET_ALL_QUESTIONS", response.data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listQuestionPagination({ commit }, data) {
    try {
      commit("SET_QUESTIONS", []);
      const response = await questionService.getAllQuestions(data);
      const pagination = {
        page: response?.data.page,
        limit: response?.data.limit,
        totalItems: response?.data.totalItems,
        totalPages: response?.data.totalPages,
      };
      commit("SET_QUESTIONS", response?.data || []);
      commit("SET_QUESTIONS_PAGINATION", pagination || {});
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  cleanActionPlan({ commit }) {
    commit("SET_QUESTIONS", []);
    commit("SET_QUESTIONS_PAGINATION", {
      page: 0,
      limit: 0,
      totalItems: 0,
      totalPages: 0,
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
