import { http } from '../../../api/http-common';

const getAllEvidencesActionPlan = async (params) => {
	try {
		const { data } = await http.get("evidences-plan-action", { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getEvidenceActionPlanById = async (id) => {
	try {
		const { data } = await http.get(`evidences-plan-action/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveUploadEvidenceActionPlan = async (payload) => {
	try {
		const { data } = await http.post('evidences-plan-action/upload-evidence', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const confirmObservedEvidence = async (payload) => {
	try {
		const { data } = await http.post('evidences-plan-action/confirm-evidence', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const reverseConfirmedEvidence = async (id) => {
	try {
		const { data } = await http.put("evidences-plan-action/reverse-confirmed-evidence/" + id);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteEvidenceActionPlan = async (id) => {
	try {
		const { data } = await http.delete("evidences-plan-action/" + id);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	getAllEvidencesActionPlan,
	getEvidenceActionPlanById,
	saveUploadEvidenceActionPlan,
	confirmObservedEvidence,
	reverseConfirmedEvidence,
	deleteEvidenceActionPlan
};
